import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Cancel';
import Button from '@mui/material/Button';
import styled from "styled-components";
import { MontserratBoldBlack24px} from "../styledMixins";
import { useState } from "react";

function Modal({ children, handleClose }) {

  return (
    <ModalBox>
      <ModalArea>
        <ModalClose>
          <a href='#void' onClick={handleClose}>
            <IconButton aria-label="Fechar">
              <CloseIcon color="#fff"/>
            </IconButton>
          </a>
        </ModalClose>
        <DivTitle>
          <span>Termo de aceite (Leia com atenção)</span>
        </DivTitle>
        { children }
      </ModalArea>
    </ModalBox>
  )
}


const ModalBox = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

const ModalArea = styled.div`
  width: 100%;
  height: 710px;
  background: #fff;
  box-shadow: 2px 2px 10px 0px grey;
  @media only screen and (max-width: 600px) {
    position: fixed;
    inset: 0;
    height: 750px;
    overflow: auto;
  }
`;

const ModalClose = styled.div`
  width: 100%;
  text-align: right;
  height: 10px;
  background-color: #237aba;

  > a > button > svg {
    color: #fff;
  }
`;

const DivTitle = styled.div`
  text-align: center;
  background-color: #237aba;

  > span {
    ${MontserratBoldBlack24px}
    line-height: 60px;
    letter-spacing: -0.06px;
    padding-top: 15px;
    color: #fff;
    @media only screen and (max-width: 600px) {
      font-size: 14px;
    }

  }
`;

export default Modal;