import * as React from 'react';
import { useState } from "react";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import livro_atual from "./assets/img/livro_9.png";
import styled from "styled-components";
import { MontserratBoldBlack24px, MontserratNormalBlack18px , } from "./styledMixins";
import { projectFirestore } from './firebase/config';
import Modal from './components/Modal';
import backgroundModal from "./assets/img/fundo_ok.gif";


function RetiradaLivro() {
  const [name, setName] = useState('');
  const [showModal, setShowModal] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    const retirada = {
      name: name,
      book: 9,
      session: 2022
    }

    try {
      projectFirestore.collection('retiradas').add(retirada);
      setShowModal(true)
    } catch(err) {
      console.log(err);
    }
  };

  const handleClose = () => {
    setName('')
    setShowModal(false)
  }

  return(
    <div className="container-center-horizontal">
      <Title>Gente! Gente! Gente!</Title>
      <SubTitle>Marca ai seu nome pra sabermos quem já retirou o livro!</SubTitle>
      <Livro src={livro_atual} />
        <Box
          component="form"
          sx={{
            width: '75%',
            textAlign: 'center',
            paddingTop: '20px',
            '& .MuiTextField-root': { m: 1, width: '100%' },
            }}
            autoComplete="off"
            onSubmit={handleSubmit}
          >
          <div>
            <TextField
              id="name"
              label="Nome"
              required
              variant="standard"
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
          </div>
          <Button
            variant="contained"
            sx={{ background: '#4EC8C1' }}
            type='submit'
            endIcon={<SendIcon />}
          >
            Enviar
          </Button>
        </Box>
      { showModal && <Modal handleClose={handleClose}>
        <RetiradaOK>
          <div>
            <Title>Aeeeeee {name.split(' ')[0]} :D !!!!</Title>
          </div>
          <div style={{paddingTop: '40px'}}>
            <SubTitle>Agora que vc já retirou o livro é hora de ler!!!!<br /><br /> E não esquece, nosso próximo encontro é dia 22/09 20h.</SubTitle>
          </div>
        </RetiradaOK>
      </Modal> }
    </div>
  );
}

const RetiradaOK = styled.div`
  background-image: url(${backgroundModal});
  background-size: auto;
  height: 80%;
  text-align: center;
`;

const Title = styled.span`
  ${MontserratBoldBlack24px}
  line-height: 60px;
  letter-spacing: -0.06px;
  padding-top: 15px;
`;

const SubTitle = styled.span`
  ${MontserratNormalBlack18px}
  letter-spacing: -0.06px;
  padding-top: 15px;
  text-align: center;
`;

const Livro = styled.img`
  @media(max-width: 820px){
    width: 40%;
    padding: 0;
    margin-bottom: 30px;
    margin-top: 30px;
  }
`;

export default RetiradaLivro;
