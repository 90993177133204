import { css } from "styled-components";

export const MontserratMediumMineShaft24px = css`
  color: var(--mine-shaft);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-m);
  font-weight: 500;
  font-style: normal;
`;

export const MontserratNormalEerieBlack36px = css`
  color: var(--eerie-black);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xxl);
  font-weight: 400;
  font-style: normal;
`;

export const MontserratBoldBlack24px = css`
  color: var(--black);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-m);
  font-weight: 700;
  font-style: normal;
`;

export const MontserratNormalBlack18px = css`
  color: var(--black);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xs);
  font-weight: 400;
  font-style: normal;
`;

export const MontserratLightBlack18px = css`
  color: var(--black);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xs);
  font-weight: 400;
  font-style: normal;
`;

export const MontserratBoldEerieBlack36px = css`
  color: var(--eerie-black);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xxl);
  font-weight: 700;
  font-style: normal;
`;

export const MontserratNormalEerieBlack18px = css`
  color: var(--eerie-black);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xs);
  font-weight: 400;
  font-style: normal;
`;

export const MontserratLightBlack32px = css`
  color: var(--black);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xl);
  font-weight: 300;
  font-style: normal;
`;

export const MontserratBoldBlacUnderlinek36px = css`
  color: var(--black);
  font-family: var(--font-family-montserrat);
  font-size: 36px;
  font-weight: 700;
  font-style: normal;
  text-decoration-line: underline;
`;

export const MontserratBoldBlack40px = css`
  color: var(--black);
  font-family: var(--font-family-montserrat);
  font-size: 40px;
  font-weight: 700;
  font-style: normal;
`;

export const MontserratBoldBlacUnderlinek40px = css`
  color: var(--black);
  font-family: var(--font-family-montserrat);
  font-size: 40px;
  font-weight: 700;
  font-style: normal;
  text-decoration-line: underline;
`;

export const MontserratBoldBlack48px = css`
  color: var(--black);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xxxl);
  font-weight: 700;
  font-style: normal;
`;

export const MontserratBoldBlack18px = css`
  color: var(--black);
  font-family: var(--font-family-montserrat);
  font-size: 18px;
  font-weight: 700;
  font-style: normal;
`;

export const MontserratNormalBlack24px = css`
  color: var(--black);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-m);
  font-weight: 400;
  font-style: normal;
`;

export const MontserratLightBlack16px = css`
  color: var(--black);
  font-family: var(--font-family-montserrat);
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
`;


export const MontserratLightBlack24px = css`
  color: var(--black);
  font-family: var(--font-family-montserrat);
  font-size: 24px;
  font-weight: 300;
  font-style: normal;
`;

export const MontserratBoldWhite24px = css`
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-m);
  font-weight: 700;
  font-style: normal;
`;

export const MontserratBoldEerieBlack24px = css`
  color: var(--eerie-black);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-m);
  font-weight: 700;
  font-style: normal;
`;

export const MontserratNormalEerieBlack24px = css`
  color: var(--eerie-black);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-m);
  font-weight: 400;
  font-style: normal;
`;

export const MontserratMediumSmashedPumpkin18px = css`
  color: var(--smashed-pumpkin);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xs);
  font-weight: 500;
  font-style: normal;
`;

export const MontserratMediumBlack22px = css`
  color: var(--black);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-s);
  font-weight: 500;
  font-style: normal;
`;

export const MontserratMediumBlack18px = css`
  color: var(--black);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xs);
  font-weight: 500;
  font-style: normal;
`;

export const MontserratBoldMineShaft36px = css`
  color: var(--mine-shaft);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xxl);
  font-weight: 700;
  font-style: normal;
`;

export const MontserratBoldMineShaft48px = css`
  color: var(--mine-shaft);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xxxl);
  font-weight: 700;
  font-style: normal;
`;

export const MontserratMediumBlack32px = css`
  color: var(--black);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xl);
  font-weight: 500;
  font-style: normal;
`;

export const MontserratBoldBlack32px = css`
  color: var(--black);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xl);
  font-weight: 700;
  font-style: normal;
`;
