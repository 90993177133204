import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import Home from "./Home";
import RetiradaLivro from "./RetiradaLivro";
import Temporada2025 from "./Temporada2025";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/retirada_livro" element={<RetiradaLivro />}></Route>
        <Route path="/temporada-2025" element={<Temporada2025 />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
