import { isMobile } from "react-device-detect";
import { Widget } from "@typeform/embed-react";

function Home() {
  if (isMobile) {
    return (
      <div className="container-center-horizontal">
        <Widget id="lE7oOeBN" style={{ width: "100%", height: "100%", padding: "50px" }} className="my-form" />;
      </div>
    );
  } else {
    return (
      <div className="container-center-horizontal">
        <Widget id="lE7oOeBN" style={{ width: "100%", height: "100%", padding: "100px" }} className="my-form" />;
      </div>
    );
  }
}

export default Home;