import { isMobile } from "react-device-detect";
import styled from "styled-components";
import background from "./assets/img/temporada_2025.jpeg";

function Home() {
  if (isMobile) {
    return (
      <div className="container-center-horizontal">
        <BackgroundImage src={background} />
      </div>
    );
  } else {
    return (
      <div className="container-center-horizontal">
        <BackgroundImage src={background} />
      </div>
    );
  }
}

const BackgroundImage = styled.img`
  @media (max-width: 820px) {
    width: 150%;
    padding: 0;
    margin-bottom: 30px;
    margin-top: 30px;
  }
`;

export default Home;