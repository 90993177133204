import firebase from 'firebase/app'
import 'firebase/firestore'

const firebaseConfig = {
  apiKey: "AIzaSyAeFXkipsdgcbuxZPxzBvodYaxh23fXFws",
  authDomain: "cala-b9cd4.firebaseapp.com",
  projectId: "cala-b9cd4",
  storageBucket: "cala-b9cd4.appspot.com",
  messagingSenderId: "166167573572",
  appId: "1:166167573572:web:d8bfa9c46bff78bd1e7811",
  measurementId: "G-C1MLEX885Q"
};

//init firebase
firebase.initializeApp(firebaseConfig)

//init services
const projectFirestore = firebase.firestore()


export { projectFirestore }